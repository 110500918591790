import React from "react"
import { FirstSection } from "../components/Sections"
import { CTA_NAMES, CTA } from "../components/CTAs"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Typography } from "@material-ui/core"

import ContactTreasury from "../components/ContactTreasury/ContactTreasury"

const Treasury = () => {
  return (
    <Layout>
      <SEO title="Treasury" />
      <FirstSection
        image="BitcoinBg"
        h1="Bitcoin for Corporations"
        boxHeader="Add bitcoin to your balance sheet"
      >
        <Typography variant="body1">
          For CFOs and executive teams who want bitcoin in their corporate
          treasury. Whether you are a small business or a listed enterprise, we
          will help you efficiently buy and securely custody bitcoin. <br />
          <br /> Coming February 2021. Register below.
        </Typography>
        <ContactTreasury />
      </FirstSection>
    </Layout>
  )
}
export default Treasury
